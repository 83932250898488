<template>
  <q-btn
    no-caps
    icon="dashboard_customize"
    align="left"
    color="primary"
    @click="visible = true"
    :label="$t('label.rich_menu_template')"
    class="q-mt-sm"
    style="height: 40px"
  />
  <q-dialog v-model="visible" full-height position="right">
    <q-card class="column" style="width: 1200px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>{{ $t('label.rich_menu_template') }}</q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section class="col">
        <CLoading :loading="loading" />
        <q-splitter v-model="splitterModel" :limits="[30, 30]" style="height: 100%">
          <template v-slot:before>
            <TemplateSelector
              :selected-template="selectedTemplate"
              :selected-template-compact="selectedTemplateCompact"
              :visible="visible"
              @on-select-template="selectTemplate"
            ></TemplateSelector>
          </template>

          <template v-slot:after>
            <TemplateSettingForm
              ref="templateSettingForm"
              :selected-template-compact="selectedTemplateCompact"
              :visible="visible"
              @on-submit-template="submitTemplate"
            ></TemplateSettingForm>
          </template>
        </q-splitter>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
        <q-btn no-caps color="primary" @click="mergeImages">{{ $t('next') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import {
  IRichMenuTemplate,
  IRichMenuTemplateCompact,
} from '@/utils/types'
import { Prop } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import TemplateSelector from '@/components/rich-menus/selectors/TemplateSelector.vue'
import TemplateSettingForm from '@/components/rich-menus/selectors/TemplateSettingForm.vue'

@Options({
  components: { TemplateSettingForm, TemplateSelector, MediaUploader, CLoading },
  directives: { maska },
  emits: ['onSubmitTemplate'],
})
export default class RichMenuTemplate extends Vue {
  @Prop()
  selectedTemplateCompact!: IRichMenuTemplateCompact

  loading = false
  visible = false
  splitterModel = 30

  selectedTemplate: IRichMenuTemplate = {}

  onCancel() {
    this.$refs.templateSettingForm.resetAll()
    this.visible = false
  }

  async selectTemplate(item) {
    this.selectedTemplate = item
    this.$refs.templateSettingForm.onTemplateSelected(item)
  }

  async mergeImages() {
    this.loading = true
    this.$refs.templateSettingForm.mergeImages()
  }

  async submitTemplate(template) {
    this.$emit('onSubmitTemplate', template)
    this.loading = false
    this.onCancel()
  }
}
</script>

<style lang="scss" scoped>
.template-container {
  width: 700px;
  min-height: 200px;
  position: relative;
}
.target {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed #44aaff;
  white-space: nowrap;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
}
.target.active {
  border: 2px solid #0048ff !important;
}
</style>
