<template>
  <div>
    <v-chart ref="current" class="chart" :option="option" autoresize />
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import VChart from 'vue-echarts'
import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { TooltipComponent, GridComponent, TitleComponent, LegendComponent } from 'echarts/components'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { Formater } from '@/utils/formater'

echarts.use([LineChart, CanvasRenderer, TooltipComponent, GridComponent, TitleComponent, LegendComponent])

@Options({
  components: {
    VChart,
  },
  directives: { maska },
})
export default class EChart extends BaseFormMixin {
  @Prop()
  titleChart!: string

  @Prop()
  xAxisData!: []

  @Prop()
  yAxisData!: []

  @Prop()
  chartColor!: string

  @Prop()
  chartBgColor!: string

  @Prop()
  seriesData!: []

  @Prop()
  legendData!: []

  @Prop()
  showLegend!: boolean

  option = {
    legend: {
      data: this.legendData,
      show: this.showLegend,
    },
    grid: {
      left: '10%',
      top: '10%',
      right: 20,
      bottom: '15%',
    },
    color: [this.chartColor, '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    title: {
      text: this.titleChart,
      left: 'left',
      top: 'left',
      textStyle: {
        fontSize: 12,
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: this.xAxisData,
    },
    tooltip: {
      trigger: 'axis',
      // formatter: '{a} <br/>{b} : {c} ',
      formatter: (params) => {
        const total = params.length
        let tooltip = ''
        for (let index = 0; index <= total - 1; index++) {
          if (index === 0) {
            tooltip += `<div style="width: 100%; text-align: left;">${params[index].axisValue}</div>`
          }
          tooltip += `<div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; min-width: 80px;"><span>${params[index].seriesName}:</span><span>${this.numberWithCommas(params[index].data)}</span></div>`
        }
        return tooltip
      },
    },

    yAxis: {
      type: 'value',
    },
    series: this.seriesData,
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }
}
</script>

<style scoped>
.chart {
  min-height: 250px;
  text-align: center !important;
}
</style>
