
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import {
  IRichMenuTemplate,
  IRichMenuTemplateCompact,
} from '@/utils/types'
import { Prop } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import TemplateSelector from '@/components/rich-menus/selectors/TemplateSelector.vue'
import TemplateSettingForm from '@/components/rich-menus/selectors/TemplateSettingForm.vue'

@Options({
  components: { TemplateSettingForm, TemplateSelector, MediaUploader, CLoading },
  directives: { maska },
  emits: ['onSubmitTemplate'],
})
export default class RichMenuTemplate extends Vue {
  @Prop()
  selectedTemplateCompact!: IRichMenuTemplateCompact

  loading = false
  visible = false
  splitterModel = 30

  selectedTemplate: IRichMenuTemplate = {}

  onCancel() {
    this.$refs.templateSettingForm.resetAll()
    this.visible = false
  }

  async selectTemplate(item) {
    this.selectedTemplate = item
    this.$refs.templateSettingForm.onTemplateSelected(item)
  }

  async mergeImages() {
    this.loading = true
    this.$refs.templateSettingForm.mergeImages()
  }

  async submitTemplate(template) {
    this.$emit('onSubmitTemplate', template)
    this.loading = false
    this.onCancel()
  }
}
