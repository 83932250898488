import { render } from "./InitQuickReply.vue?vue&type=template&id=56c423c8&scoped=true"
import script from "./InitQuickReply.vue?vue&type=script&lang=ts"
export * from "./InitQuickReply.vue?vue&type=script&lang=ts"

import "./InitQuickReply.vue?vue&type=style&index=0&id=56c423c8&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-56c423c8"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QToggle,QItemLabel,QInput});
