
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import EChart from '@/components/app/EChart.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { IDateRange, IOverViewRequest } from '@/utils/types'
import { maska } from 'maska'
import { ChartModel } from '@/models/chart-model'
import { ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { Formater } from '@/utils/formater'

import { last7Days, now } from '@/utils/datetime'

@Options({
  components: {
    DatePicker,
    CLoading,
    EChart,
  },
  directives: { maska },
})
export default class CampaignDetails extends mixins(BaseFormMixin) {
  loading = true
  firstLoad = true
  componentKey = 1
  chart_title = ''

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  friendsChart: ChartModel = new ChartModel([], [])

  campaignsStatistic: IOverViewRequest[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedCampaignType() {
    return this.$route.params.campaign_type || ''
  }

  get selectedCampaignId() {
    return this.$route.params.campaign_id || ''
  }

  async fetchSelectingApp() {
    this.campaignsStatistic = []
    if (!this.selectedCampaignId) {
      return
    }

    this.loading = true
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }

    if (date_from !== '' && date_to !== '') {
      let action = ''
      if (this.selectedCampaignType === 'initial') {
        action = ACTION_INITIAL.OVERVIEW_DETAIL
      } else {
        action = ACTION_PUSH.OVERVIEW_DETAIL
      }
      console.log(action)
      const resp = await this.$store.dispatch(action, {
        app_id: this.selectedAppId,
        campaign_id: this.selectedCampaignId,
        is_test_mode: false,
        date_from: date_from,
        date_to: date_to,
      })
      if (resp) {
        // eslint-disable-next-line
        this.campaignsStatistic = resp[this.selectedCampaignType]
      }
    }
    this.initCharts()
    this.loading = false
  }

  initCharts(): void {
    this.friendsChart = new ChartModel([], [])
    const total = this.campaignsStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.campaignsStatistic[index]
      this.friendsChart.xAxis.push(item.date)
    }

    this.componentKey++
  }

  get seriesData() {
    const delivered: number[] = []
    const total_user_of_goal: number[] = []
    const gtr: number[] = []
    const total_click: number[] = []
    const success_rate: number[] = []
    const success_count: number[] = []
    const total = this.campaignsStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.campaignsStatistic[index]
      delivered.push(item.delivered)
      total_user_of_goal.push(item.total_user_of_goal)
      gtr.push(item.gtr)
      total_click.push(item.total_click)
      success_rate.push(item.success_rate)
      success_count.push(item.success_count)
    }

    return [
      {
        name: this.$t('label.delivered'),
        type: 'line',
        data: delivered,
      },
      {
        name: this.$t('label.total_user_of_goal'),
        type: 'line',
        data: total_user_of_goal,
      },
      {
        name: this.$t('label.gtr'),
        type: 'line',
        data: gtr,
      },
      {
        name: this.$t('label.total_click'),
        type: 'line',
        data: total_click,
      },
      {
        name: this.$t('label.success_rate'),
        type: 'line',
        data: success_rate,
      },
      {
        name: this.$t('label.success_count'),
        type: 'line',
        data: success_count,
      },
    ]
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }
    this.dateRange = value
    this.fetchSelectingApp()
  }

  @Watch('selectedCampaignId', { immediate: true })
  selectedCampaignIdChanged() {
    this.dateRange = {
      from: last7Days(),
      to: now(),
    }
    this.fetchSelectingApp()
    this.firstLoad = false
  }

  mounted() {
    if (this.selectedCampaignType === 'initial') {
      this.chart_title = this.$t('label.initial.chart_title')
    } else {
      this.chart_title = this.$t('label.push.chart_title')
    }
  }
}
