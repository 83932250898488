<template>
  <div class="q-mb-sm q-mt-sm">
    <draggable
      v-if="value.length"
      :list="value"
      itemKey="image"
      v-bind="{
        animation: 200,
        group: 'welcome',
        disabled: false,
      }"
      @change="(evt) => onDragEnd(evt, value)"
      handle=".cursor-move"
      :component-data="{
        type: 'transition-group',
        name: 'drag-area',
        class: 'q-list q-list--dense q-list--separator',
      }"
      :forceFallback="true"
      fallbackClass="dragging-item"
    >
      <template #item="{ element: menu, index }">
        <div class="q-py-sm q-list--bordered">
          <q-item>
            <q-item-section>
              <label class="text-grey-9 text-bold">{{ $t('label.rich_menu.label') }}</label>
              <q-input
                :rules="[(val) => (val && val.length > 0) || requiredRule.label]"
                outlined
                v-model="menu.label"
                maxlength="20"
                dense
                :placeholder="$t('label.rich_menu.label')"
              >
              </q-input>
              <label class="text-grey-9 text-bold">{{ $t('label.rich_menu.image_requirements') }}</label>
              <span>{{ $t('label.rich_menu.image_requirements_detail1') }} </span>
              <span>{{ $t('label.rich_menu.image_requirements_detail2') }} </span>
              <span>{{ $t('label.rich_menu.image_requirements_detail3') }} </span>
              <span>{{ $t('label.rich_menu.image_requirements_detail4') }} </span>
              <span>{{ $t('label.rich_menu.image_requirements_detail5') }} </span>
              <span v-if="errorImageMessage !== ''">
                <div class="text-red">{{ errorImageMessage }}</div>
              </span>
              <q-btn
                no-caps
                color="primary"
                @click="onSettingMenu(index)"
                :label="$t('label.common.answer.setting_tappable_areas')"
                :disable="!menu.label || !menu.image_url"
              />

              <span v-if="!isValidArea" class="q-pl-sm">
                <div class="text-red">{{ requiredRule.settingTappableAreas }}</div>
              </span>
            </q-item-section>
            <q-item-section v-if="menu.image_url">
              <q-img :src="menu.image_url" />
            </q-item-section>
            <q-item-section side top>
              <q-btn
                size="sm"
                no-caps
                round
                outline
                icon="delete"
                color="red"
                @click="onRemoveImage(index)"
                v-if="menu.image_url"
              />
            </q-item-section>
            <q-item-section @click="handleFocusMenu(index)" side top>
              <div class="column">
                <div class="q-mb-sm">
                  <q-file
                    name="image_file"
                    :label="$t('label.common.messages.add_image')"
                    outlined
                    dense
                    accept=".png, .jpeg"
                    v-model="file"
                    bg-color="btn-upload"
                    label-color="btn-upload"
                    class="q-file-btn"
                  >
                    <template v-slot:prepend>
                      <q-icon name="attach_file" size="xs" color="white" style="font-weight: bold" />
                    </template>
                  </q-file>
                </div>
                <MediaUploader
                  :is-crop-image="true"
                  :crop-ratio="'1:45'"
                  :aspect-ratio="1.45"
                  acceptFileTypes=".png, .jpeg"
                  @on-select-media="onSelectMedia"
                ></MediaUploader>
                <RichMenuTemplate
                  :selected-template-compact="selectedTemplate"
                  @on-submit-template="onSubmitTemplate"
                />
              </div>
            </q-item-section>
            <q-item-section side>
              <div class="q-gutter-xs">
                <q-btn size="sm" no-caps outline color="primary" round icon="add" @click="handleAddMenu(index)" />
                <q-btn
                  no-caps
                  outline
                  color="red"
                  size="sm"
                  icon="remove"
                  round
                  @click="handleDeleteMenu(index)"
                  :disabled="value.length === 1"
                />
              </div>
            </q-item-section>
          </q-item>
        </div>
      </template>
    </draggable>
    <UploadingProgress :files="cropFiles" />
    <CropperImageSelector
      v-if="cropperImageModalVisible"
      :modalVisible="cropperImageModalVisible"
      :file="file"
      @update:closeModal="onCloseModal"
      @update:onCropImage="onCropImage"
      :aspectRatio="1.45"
    />
  </div>

  <SettingTappableModal
    v-if="modalTappableAreasVisible"
    :modalVisible="modalTappableAreasVisible"
    v-model="selectedMenu.tappable_area"
    :defaultImage="defaultSize"
    :isInitialMessage="true"
    :isPushMessage="true"
    :isURL="true"
    :isMessage="true"
    :isSwitchMenu="isSwitchMenu"
    :richMenuContent="value"
    :currentMenuId="selectedMenu.rich_menu_alias_id"
    @update:closeModal="onCloseTappableAreasModal"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { calcOrderingDragend } from '@/utils/helpers'
import draggable from 'vuedraggable'
import { constant } from '@/utils/constants'
import { IImageUrlSize, IMedia, IRichMenuContent, IRichMenuTemplateCompact } from '@/utils/types'
import { NumberUtils } from '@/utils/number'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import SettingTappableModal from '@/components/tappable-area/SettingTappableModal.vue'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import RichMenuTemplate from '@/components/rich-menus/selectors/RichMenuTemplate.vue'

@Options({
  components: {
    RichMenuTemplate,
    MediaUploader,
    draggable,
    UploadingProgress,
    SettingTappableModal,
    CropperImageSelector,
  },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class RichMenuSelector extends Vue {
  @Prop({ default: [] })
  modelValue!: IRichMenuContent[]

  selectedMenu!: IRichMenuContent
  defaultSize: IImageUrlSize = {}
  modalTappableAreasVisible = false
  cropperImageModalVisible = false
  errorImageMessage = ''
  isValidArea = true
  isTestMode = false
  file: File | null = null
  cropFiles: File[] = []
  selectedTemplate: IRichMenuTemplateCompact = {}

  get value() {
    return this.modelValue
  }

  set value(value: IRichMenuContent[]) {
    this.$emit('update:modelValue', value)
  }

  get isSwitchMenu() {
    if (this.value.length > 1) {
      return true
    }

    return false
  }

  get requiredRule() {
    const requiredRule = {
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
      label: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.rich_menu.label'),
      }),
    }
    return requiredRule
  }

  handleSelectedMenu() {
    let width = 0
    let height = 0
    if (!this.selectedMenu) {
      return
    }
    if (this.selectedMenu.image_width) {
      width =
        this.selectedMenu.image_width * (constant.RICH_MENU_IMAGE.IMAGE_DEFAULT_SIZE / this.selectedMenu.image_width)
    }
    if (this.selectedMenu.image_height && this.selectedMenu.image_width) {
      height =
        this.selectedMenu.image_height * (constant.RICH_MENU_IMAGE.IMAGE_DEFAULT_SIZE / this.selectedMenu.image_width)
    }

    this.defaultSize = {
      width,
      height,
      image_url: this.selectedMenu.image_url,
    }
  }

  onRemoveImage(index: number) {
    if (this.value) {
      this.value[index].image_url = ''
    }
  }

  validate() {
    this.isValidArea = true
    this.errorImageMessage = ''
    const images = this.value?.find((item) => item.image_url === '')
    if (images) {
      this.errorImageMessage = this.requiredRule.messageImage
    }

    if (this.errorImageMessage !== '' || !this.isValidArea) {
      return false
    }

    return true
  }

  async validateInputFile(file: File) {
    this.errorImageMessage = ''

    if (!file && this.selectedMenu.image_url === '') {
      this.errorImageMessage = this.requiredRule.messageImage
      return false
    }

    if (file.size > constant.RICH_MENU_IMAGE.MAX_FILE_SIZE) {
      this.errorImageMessage = this.$t('validate.file_too_big', {
        placeholder: file.name,
      })

      return false
    }

    let result = true
    if (file) {
      result = await this.validateImageSize(file)
    }

    return result
  }

  async validateImageSize(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement
        if (data.width / data.height < constant.RICH_MENU_IMAGE.MIN_ASPECT_RATIO) {
          this.errorImageMessage = this.$t('validate.image_aspect_ratio', {
            placeholder: file.name,
          })
          resolve(false)
        }

        if (
          data.width < constant.RICH_MENU_IMAGE.MIN_IMAGE_WIDTH ||
          data.width > constant.RICH_MENU_IMAGE.MAX_IMAGE_WIDTH
        ) {
          this.errorImageMessage = this.$t('validate.image_width_size', {
            placeholder: file.name,
          })
          resolve(false)
        }

        if (data.height < constant.RICH_MENU_IMAGE.MIN_IMAGE_HEIGHT) {
          this.errorImageMessage = this.$t('validate.image_height_size', {
            placeholder: file.name,
          })
          resolve(false)
        }

        this.selectedMenu.image_width = data.width
        this.selectedMenu.image_height = data.height
        resolve(true)
      }
    })
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateInputFile(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: '1:45',
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.cropFiles.push(this.file)
        await this.submitFile(this.file)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  onSettingMenu(index) {
    if (this.value) {
      this.selectedMenu = this.value[index]
      this.handleSelectedMenu()
    }
    this.modalTappableAreasVisible = true
  }

  handleFocusMenu(index: number) {
    if (this.value) {
      this.selectedMenu = this.value[index]
      if (this.selectedMenu.selected_template) {
        this.selectedTemplate = this.selectedMenu.selected_template
        this.selectedTemplate.tappable_area = this.selectedMenu.tappable_area
      } else {
        this.selectedTemplate = {}
      }
    }
  }

  handleAddMenu(index: number): void {
    const newAnser: IRichMenuContent = {
      label: '',
      rich_menu_alias_id: NumberUtils.uniqueId(),
      size: {
        width: constant.RICH_MENU_IMAGE.MAX_IMAGE_WIDTH,
        height: constant.RICH_MENU_IMAGE.MAX_IMAGE_HEIGHT,
      },
      tappable_area: [],
      image_url: '',
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newAnser)
      this.selectedTemplate = {}
    }
  }

  handleDeleteMenu(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.selectedTemplate = {}
    }
  }

  onCloseTappableAreasModal() {
    this.selectAnswer = null
    this.modalTappableAreasVisible = false
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IRichMenuContent[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  async onSelectMedia(file: IMedia) {
    if (file) {
      this.selectedMenu.image_url = file.url || ''
      this.selectedMenu.content_type = file.content_type
      this.selectedMenu.image_width = file.image_width
      this.selectedMenu.image_height = file.image_height
      if (file.image_width && file.image_height) {
        this.selectedMenu.size = {
          width: file.image_width,
          height: file.image_height,
        }
      }
    }
  }

  async onSubmitTemplate(file) {
    if (file) {
      this.selectedMenu.image_url = file.url || ''
      this.selectedMenu.content_type = file.content_type
      this.selectedMenu.image_width = file.image_width
      this.selectedMenu.image_height = file.image_height
      if (file.image_width && file.image_height) {
        this.selectedMenu.size = {
          width: file.image_width,
          height: file.image_height,
        }
      }
      if (file.tappable_area) {
        this.selectedMenu.tappable_area = file.tappable_area
      }
      this.selectedMenu.selected_template = file.selected_template
      this.handleSelectedMenu()
      this.modalTappableAreasVisible = true
    }
  }

  async submitFile(file: File) {
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded) {
        this.selectedMenu.image_url = uploaded.file_url_org || ''
        this.selectedMenu.content_type = file.type
      }
    } catch (error) {
      console.log(error)
    }
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.errorImageMessage = ''
    this.file = null
    this.cropFiles = []
  }

  async onCropImage(file, width, height) {
    this.selectedMenu.image_width = width
    this.selectedMenu.image_height = height
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }
}
</script>
<style lang="scss" scoped>
:deep(.q-file-btn .q-field__prepend) {
  padding-right: 8px;
}
:deep(.q-file-btn .q-field__inner:before) {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
:deep(.q-file-btn .q-field__control:hover) {
  opacity: 0.9;
}
:deep(.q-file-btn .q-field__control:hover:before) {
  border: 0;
}
</style>
