export const NumberUtils = {
  round: (num: number, decimalLength = 1) => {
    if (decimalLength <= 0) {
      return num
    }

    const roundNumber = decimalLength * 10
    return Math.round(num * roundNumber) / roundNumber
  },
  /* tslint:disable:no-bitwise */
  uniqueId: () => {
    return 'xxxxxxxx-xxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },
}
