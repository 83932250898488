import { render } from "./TemplateSelector.vue?vue&type=template&id=7002efc3&scoped=true"
import script from "./TemplateSelector.vue?vue&type=script&lang=ts"
export * from "./TemplateSelector.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-7002efc3"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QRadio});
