<template>
  <div class="q-mt-md">
    <label class="text-grey-9 text-bold">{{ $t('label.initial.init_quick_reply') }}</label>
    <q-list bordered separator class="bg-grey-2">
      <q-item>
        <q-item-section side top>
          <q-toggle v-model="value.is_auto_start" :label="$t('label.initial.auto_start')" />
          <q-item-label caption class="text-caption-hint">友達追加後、自動的にメッセージを開始します</q-item-label>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section side style="width: 150px" top>
          <label class="text-grey-9 text-bold q-mt-sm">{{ $t('label.initial.start_survey') }}</label></q-item-section
        >
        <q-item-section>
          <q-input
            :disable="value.is_auto_start"
            dense
            outlined
            v-model="value.start_survey"
            :placeholder="$t('label.initial.start_survey')"
            maxlength="20"
            ref="startSurvey"
            :rules="value.is_start_survey ? [(val) => (val && val.length > 0) || requiredRule.startSurvey] : []"
          />
          <q-item-label caption class="q-mt-sm text-caption-hint"
            >ユーザーがボタンをタップするとメッセージを開始します</q-item-label
          >
        </q-item-section>

        <q-item-section side top>
          <q-toggle
            v-model="value.is_start_survey"
            :disable="value.is_auto_start"
            @update:model-value="toogleStartEventCalled"
          />
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section side style="width: 150px" top>
          <label class="text-grey-9 text-bold q-mt-sm">{{ $t('label.initial.restart_survey') }}</label></q-item-section
        >
        <q-item-section>
          <q-input
            dense
            outlined
            v-model="value.restart_survey"
            :placeholder="$t('label.initial.restart_survey')"
            maxlength="20"
            :rules="value.is_restart_survey ? [(val) => (val && val.length > 0) || requiredRule.restartSurvey] : []"
            ref="restartSurvey"
          >
          </q-input>
          <q-item-label caption class="q-mt-sm text-caption-hint"
            >ユーザーがボタンをタップするとメッセージが最初からやり直しになります</q-item-label
          >
        </q-item-section>

        <q-item-section side top>
          <q-toggle
            round
            v-model="value.is_restart_survey"
            @update:model-value="toogleEventCalled"
            val="value.is_restart_survey"
          />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IInitQuickReply } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class InitQuickReply extends Vue {
  @Prop({
    default: {
      is_auto_start: true,
      start_survey: '',
      is_start_survey: false,
      restart_survey: '',
      is_restart_survey: false,
    },
  })
  modelValue!: IInitQuickReply

  get requiredRule() {
    const requiredRule = {
      startSurvey: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.initial.start_survey'),
      }),
      restartSurvey: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.initial.restart_survey'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: IInitQuickReply) {
    this.$emit('update:modelValue', value)
  }

  toogleStartEventCalled() {
    this.$refs.startSurvey.focus()
    this.$refs.startSurvey.blur()
  }

  toogleEventCalled() {
    this.$refs.restartSurvey.focus()
    this.$refs.restartSurvey.blur()
  }
}
</script>
<style lang="scss" scoped>
.q-mb-20 {
  margin-bottom: 20px;
}

.text-caption-hint {
  font-size: 9px;
}
</style>
