
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { Iwelcomes } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import draggable from 'vuedraggable'

import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'

@Options({
  components: { draggable, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class WelcomeMessageForm extends Vue {
  @Prop({ default: [''] })
  modelValue!: Iwelcomes[]

  get requiredRule() {
    const requiredRule = {
      welcomeMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: Iwelcomes[]) {
    this.$emit('update:modelValue', value)
  }

  onAddDetail(index: number) {
    const newWelcome: Iwelcomes = {
      name: '',
      order: this.value.length + 1,
      fixed: false,
    }

    if (this.value) {
      this.value.splice(index + 1, 0, newWelcome)
    }
  }

  onRemoveDetail(index: number) {
    if (this.value) {
      this.value.splice(index, 1)
    }
  }
  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: Iwelcomes[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }
}
