<template>
  <div class="q-mt-md">
    <label class="text-grey-9 text-bold">{{ $t('label.initial.welcome_details') }}</label>
    <draggable
      v-if="value.length"
      :list="value"
      itemKey="image"
      v-bind="{
        animation: 200,
        group: 'welcome',
        disabled: false,
      }"
      @change="(evt) => onDragEnd(evt, value)"
      handle=".cursor-move"
      :component-data="{
        type: 'transition-group',
        name: 'drag-area',
        class: 'q-list q-list--bordered q-list--dense q-list--separator bg-grey-2',
      }"
      :forceFallback="true"
      fallbackClass="dragging-item"
    >
      <template #item="{ element: record, index }">
        <q-item>
          <q-item-section avatar class="cursor-move">
            <div class="q-gutter-xs q-mt-sm">
              <q-icon name="open_with" color="grey" size="sm" />
              <q-btn dense round unelevated color="primary">{{ index + 1 }}</q-btn>
            </div>
          </q-item-section>
          <q-item-section>
            <div class="q-mt-sm">
              <InputEmojiSelector
                v-model="record.name"
                type="textarea"
                hint="{displayName}, {userId}"
                :placeholder="$t('label.common.messages.message')"
                :rules="[(val) => (val && val.length > 0) || requiredRule.welcomeMessage]"
              />
              <q-item-label caption class="q-mt-sm text-caption-hint"
                >友達追加時に自動的に送信される最初のメッセージです</q-item-label
              >
            </div>
          </q-item-section>
          <q-item-section side top>
            <div class="q-gutter-xs q-mt-sm">
              <q-btn size="sm" round outline color="primary" icon="add" @click="onAddDetail(index)" />
              <q-btn
                round
                outline
                size="sm"
                icon="remove"
                @click="onRemoveDetail(index)"
                :disabled="value.length === 1"
              />
            </div>
          </q-item-section>
        </q-item>
      </template>
    </draggable>

    <div v-if="!value.length" class="q-mt-md">
      <q-btn size="sm" round outline color="primary" icon="add" @click="onAddDetail(0)" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { Iwelcomes } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import draggable from 'vuedraggable'

import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'

@Options({
  components: { draggable, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class WelcomeMessageForm extends Vue {
  @Prop({ default: [''] })
  modelValue!: Iwelcomes[]

  get requiredRule() {
    const requiredRule = {
      welcomeMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: Iwelcomes[]) {
    this.$emit('update:modelValue', value)
  }

  onAddDetail(index: number) {
    const newWelcome: Iwelcomes = {
      name: '',
      order: this.value.length + 1,
      fixed: false,
    }

    if (this.value) {
      this.value.splice(index + 1, 0, newWelcome)
    }
  }

  onRemoveDetail(index: number) {
    if (this.value) {
      this.value.splice(index, 1)
    }
  }
  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: Iwelcomes[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }
}
</script>
<style lang="scss" scoped>
.text-caption-hint {
  font-size: 9px;
}
</style>
