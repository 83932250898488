import { render } from "./TemplateSettingForm.vue?vue&type=template&id=0143ca68&scoped=true"
import script from "./TemplateSettingForm.vue?vue&type=script&lang=ts"
export * from "./TemplateSettingForm.vue?vue&type=script&lang=ts"

import "./TemplateSettingForm.vue?vue&type=style&index=0&id=0143ca68&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-0143ca68"

export default script
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToggle,QOptionGroup,QBtnGroup,QBtn,QFile,QIcon});
