export class ChartModel {
  public _xAxis: string[] = []
  public _yAxis: number[] = []

  constructor(_xAxis = [], _yAxis = []) {
    this._xAxis = _xAxis
    this._yAxis = _yAxis
  }

  public setPoint(xAxis: string, yAxis: number): void {
    this._xAxis.push(xAxis)
    this._yAxis.push(yAxis)
  }

  public get xAxis(): string[] {
    return this._xAxis
  }

  public get yAxis(): number[] {
    return this._yAxis
  }
}
