import { render } from "./ChartOfCampaignDetails.vue?vue&type=template&id=35070843&scoped=true"
import script from "./ChartOfCampaignDetails.vue?vue&type=script&lang=ts"
export * from "./ChartOfCampaignDetails.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-35070843"

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QTable,QTr,QTh,QTd});
