<template>
  <div class="q-pa-md">
    <div class="row">
      <div class="col-6 q-pa-sm" v-for="(item, index) in templates" :key="index">
        <q-card class="full-height flex flex-center q-pa-lg" @click="selectTemplate(item)">
          <q-img :src="item.img" />
          <q-radio
            dense
            v-model="selectedTemplate"
            :val="item"
            @update:model-value="checkRadio"
            class="absolute"
            style="top: 2px; right: 2px"
          />
        </q-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { IRichMenuTemplate, IRichMenuTemplateCompact } from '@/utils/types'
import richMenuTemplate from '@/utils/richmenu-template.json'

@Options({
  emits: ['onSelectTemplate'],
})
export default class TemplateSelector extends Vue {
  @Prop()
  selectedTemplate!: IRichMenuTemplate

  @Prop()
  selectedTemplateCompact!: IRichMenuTemplateCompact

  @Prop()
  visible!: boolean

  templates: IRichMenuTemplate[] = richMenuTemplate

  async selectTemplate(item) {
    this.$emit('onSelectTemplate', item)
  }

  async checkRadio(value) {
    await this.selectTemplate(value)
  }

  @Watch('selectedTemplateCompact')
  @Watch('visible')
  async setupTemplate() {
    if (this.visible) {
      if (this.selectedTemplateCompact._id) {
        const index = this.templates.findIndex((template) => this.selectedTemplateCompact._id === template._id)
        const item = this.templates[index]
        await this.checkRadio(item)
      }
    }
  }

  mounted() {
    this.setupTemplate()
  }
}
</script>

<style scoped lang="scss">

</style>
