
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IInitQuickReply } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class InitQuickReply extends Vue {
  @Prop({
    default: {
      is_auto_start: true,
      start_survey: '',
      is_start_survey: false,
      restart_survey: '',
      is_restart_survey: false,
    },
  })
  modelValue!: IInitQuickReply

  get requiredRule() {
    const requiredRule = {
      startSurvey: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.initial.start_survey'),
      }),
      restartSurvey: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.initial.restart_survey'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: IInitQuickReply) {
    this.$emit('update:modelValue', value)
  }

  toogleStartEventCalled() {
    this.$refs.startSurvey.focus()
    this.$refs.startSurvey.blur()
  }

  toogleEventCalled() {
    this.$refs.restartSurvey.focus()
    this.$refs.restartSurvey.blur()
  }
}
